import {
  FundingProjectRequest,
  FundingProjectRequestState
} from '../../core/interfaces/funding-project-request';
import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  Input
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  findIndex as _findIndex,
  remove as _remove,
  find as _find
} from 'lodash';
import {
  DocumentType,
  DocumentTypes,
  DocumentSubType,
  ProvideDocumentDialogInputData
} from '../interfaces/document-dialog-data';
import { BaseAppConfigService } from '../../core/config/base-app-config.service';
import { BaseAppConfig } from '../../core/config/base-app-config.dt';
import {
  forbiddenCharsInFileName,
  forbiddenSpecialChars,
  regExForFileName,
  regExForSpecialCharsExclusion
} from '../../core/util/forbidden-special-characters-regex';

@Component({
  selector: 'oaman-document-provide-dialog',
  templateUrl: './document-provide-dialog.component.html',
  styleUrls: ['./document-provide-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentProvideDialogComponent {
  /**
   * appProperties: Getting Infos from Backend-Env.
   * max_file_number: [by default: 10]
   * max_file_size_mb:[used to define the maximum file limitation which can be uploaded, 0 just to be initialized.]
   * max_file_size: [max_file_size_mb * 1024 * 1024; by default: 50Mb; 0 just to be initialized.]
   * max_delivery_file_size_mb: used to define the maximum file limitation which can be sent to service konto,
   * max_delivery_size: [max_delivery_file_size_mb * 1024 * 1024; by default: 3Mb]
   */
  appProperties!: BaseAppConfig;
  maxFileSizeMb!: number;
  maxFileSize!: number;
  maxDeliveryFileSizeMb!: number;
  maxDeliverySize!: number;
  maxFileNumber!: number;

  @Input()
  requiredFileType = `application/pdf`; // allowed file formats: .pdf
  documentSubTypes: DocumentSubType[] = [];
  documentTypes: DocumentType[] = [];
  availableDocumentTypes: DocumentType[] = [];
  availableRequests: FundingProjectRequest[];
  documentForm: UntypedFormGroup;
  selectedDocSubTypeId!: string;
  selectedDocTypeId!: string;
  selectedRequestId?: string;
  maxFileNumberReached = false;
  documentFiles: any = [];
  activeProgressBar = false; // used to display the progress bar during the backend call
  maxDocTitleAndFileNameLength = 50;
  protected readonly forbiddenSpecialChars = forbiddenSpecialChars;
  protected readonly forbiddenCharsInFileName = forbiddenCharsInFileName;

  constructor(
    private dialogRef: MatDialogRef<DocumentProvideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProvideDocumentDialogInputData,
    private translateService: TranslateService,
    private baseAppConfigService: BaseAppConfigService
  ) {
    this.appProperties = this.baseAppConfigService.loadAppConfig();
    this.maxFileSizeMb = this.appProperties.maxFileSizeMb;
    this.maxFileSize = this.maxFileSizeMb * 1024 * 1024;
    this.maxDeliveryFileSizeMb = this.appProperties.maxDeliveryFileSizeMb;
    this.maxDeliverySize = this.maxDeliveryFileSizeMb * 1024 * 1024;
    this.maxFileNumber = this.appProperties.maxFileNumber;

    this.availableRequests = data.allRequests;
    this.documentTypes = data.documentTypes;
    this.availableDocumentTypes = data.documentTypes;
    this.documentSubTypes = data.documentSubTypes;
    this.selectedDocTypeId = this.availableDocumentTypes[0].id;
    this.selectedRequestId = this.initializeSelectedRequest(
      this.availableRequests,
      data.activeRequest
    );

    this.documentForm = new UntypedFormGroup({
      documentTitle: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(this.maxDocTitleAndFileNameLength),
        Validators.pattern(regExForSpecialCharsExclusion)
      ]),
      documentType: new UntypedFormControl(this.selectedDocTypeId, [
        Validators.required
      ]),
      documentSubType: new UntypedFormControl(this.selectedDocSubTypeId),
      documentRequest: new UntypedFormControl(this.selectedRequestId, [
        Validators.required
      ])
    });

    this.onRequestChange();
  }

  onFileSelected(event: any) {
    const files: File[] = event.target.files;

    if (files?.length > 0) {
      for (const file of files) {
        if (this.documentFiles.length === this.maxFileNumber) {
          // nothing to do if we already reached the maximum limitation
          break;
        }
        var docFile = {
          fileName: file.name,
          fileBlob: file,
          fileNameMaxLengthExceeded:
            file.name.length > this.maxDocTitleAndFileNameLength,
          limitExceeded: file.size > this.maxFileSize,
          deliveryLimitExceeded: file.size > this.maxDeliverySize,
          invalidFileName: !regExForFileName.test(file.name)
        };

        // add the file only if that was not already added
        if (
          _findIndex(this.documentFiles, ['fileName', docFile.fileName]) === -1
        ) {
          this.documentFiles.push(docFile);
        }
      }
    }

    this.updateMaxFileNumber();
  }

  onFileRemove(fileToRemove: any): void {
    _remove(this.documentFiles, ['fileName', fileToRemove.fileName]);

    this.updateMaxFileNumber();
  }

  isSaveActive() {
    return (
      this.documentFiles.length > 0 &&
      !this.containsExceededFiles('limitExceeded') &&
      !this.containsExceededFiles('fileNameMaxLengthExceeded') &&
      !this.containsExceededFiles('invalidFileName')
    );
  }

  isNoticeDocumentType(): boolean {
    // we must check by the unique identifier of the notice document,
    // at this point we consider the title as unique with 'Bescheid' value
    const selectedDocType = _find(this.documentTypes, [
      'id',
      this.selectedDocTypeId
    ]);
    return selectedDocType?.title === DocumentTypes.BESCHEID;
  }

  onDocTypeChange(): void {
    const docSubTypeControl = this.documentForm.get('documentSubType');
    if (this.isNoticeDocumentType()) {
      docSubTypeControl?.setValidators(Validators.required);
    } else {
      docSubTypeControl?.clearValidators();
    }
    docSubTypeControl?.updateValueAndValidity();
  }

  onRequestChange() {
    this.refreshAvailableDocumentTypes();
    this.onDocTypeChange();
  }

  onSubmit() {
    // display the confirmation dialog in case if at least one size reached the limitation
    if (this.containsExceededFiles('deliveryLimitExceeded')) {
      var title = this.translateService.instant(
        'fundingProject.details.documents.dialog.provideDocument.docTitle'
      );
      var confirmMessage = this.translateService.instant(
        'fundingProject.details.documents.dialog.provideDocument.confirmMsg'
      );
      var submit = this.translateService.instant(
        'fundingProject.details.documents.dialog.provideDocument.submit'
      );

      this.data.dialogService
        .openDialog(title, confirmMessage, submit)
        .subscribe((value: boolean) => {
          if (value) {
            this.submitFormData();
          }
        });

      return;
    }

    this.submitFormData();
  }

  private initializeSelectedRequest(
    availableRequests: FundingProjectRequest[],
    activeRequest: string
  ): string | undefined {
    if (activeRequest && availableRequests?.length > 0) {
      var foundRequest = _find(availableRequests, ['id', activeRequest]);
      return foundRequest ? foundRequest.id : undefined;
    }

    return undefined;
  }

  private submitFormData(): void {
    this.activeProgressBar = true;

    const additionalInformation = {
      title: this.documentForm.value.documentTitle,
      documentTypeId: this.documentForm.value.documentType,
      documentSubTypeId: this.isNoticeDocumentType()
        ? this.documentForm.value.documentSubType
        : null,
      requestId: this.documentForm.value.documentRequest,
      fundingProjectTitle: this.data.fundingProjectTitle,
      fundingProjectId: this.data.fundingProjectId,
      requestTitle: this.availableRequests.find(
        (request) => request.id === this.documentForm.value.documentRequest
      )?.title
    };

    const formData = new FormData();
    this.documentFiles.forEach((file: any) => {
      formData.append('files', file.fileBlob);
    });

    formData.append(
      'documentInformation',
      this.convertToBlob(additionalInformation)
    );

    this.dialogRef.close({
      data: formData
    });
  }

  refreshAvailableDocumentTypes() {
    this.availableDocumentTypes = this.documentTypes.slice();
    const currectSelectedRequestId = this.documentForm.value.documentRequest;
    const currectSelectedRequest = _find(this.availableRequests, [
      'id',
      currectSelectedRequestId
    ]);

    /* If a request is in status decided,
       it is not possible to create a new 'BESCHEID'!
       */
    if (
      currectSelectedRequest &&
      currectSelectedRequest.status === FundingProjectRequestState.DECIDED
    ) {
      const index = _findIndex(this.availableDocumentTypes, [
        'title',
        DocumentTypes.BESCHEID
      ]);
      if (index > -1) this.availableDocumentTypes.splice(index, 1);
    }
    if (
      !this.availableDocumentTypes.find((x) => x.id == this.selectedDocTypeId)
    ) {
      this.selectedDocTypeId = this.availableDocumentTypes[0].id;
    }
  }

  private updateMaxFileNumber() {
    this.maxFileNumberReached = this.documentFiles.length >= this.maxFileNumber;
  }

  private containsExceededFiles(limitId: string) {
    return _findIndex(this.documentFiles, [limitId, true]) !== -1;
  }

  private convertToBlob(additionalFormData: any): Blob {
    return new Blob([JSON.stringify(additionalFormData)], {
      type: 'application/json'
    });
  }

  protected readonly forbiddenCharsInDocumentTitle = forbiddenCharsInFileName;
}
