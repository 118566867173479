import { FundingProject } from '../../core/interfaces/funding-project';
import {
  ChangeDetectionStrategy,
  Component,
  DEFAULT_CURRENCY_CODE,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { formatCurrency } from '@angular/common';
import { APP_CONFIG } from '../../core/util/app-config.token';
import {
  AppConfiguration,
  AssignableClerkProjectData,
  getDepartmentNameById,
  isLeaderFundingProject,
  stringToDatestring
} from '../../core/public-api';
import { GuidelineProjectIds } from '../../core/interfaces/guideline-project-ids';
import * as FundingProjectStates from '../../funding-project/funding-project-details/utils/fundingProjectStates';
import { ApplicationContext } from '../../core/constants/application-context.constants';

@Component({
  selector: 'oaman-card-funding',
  templateUrl: './card-funding.component.html',
  styleUrls: ['./card-funding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CardFundingComponent implements OnInit {
  @Input() fundingProject!: FundingProject;
  @Output() hasClicked = new EventEmitter<GuidelineProjectIds>();
  @Output() hasClickedAssignClerk =
    new EventEmitter<AssignableClerkProjectData>();
  context;
  defaultImage;
  items = [
    'Status',
    'Ort',
    'Antragsteller:in',
    'Sachbearbeiter:in',
    'Beantragt',
    'OAMan-ID',
    'Einreichungsdatum',
    'Richtlinie',
    'Amt'
  ];
  values: string[] = [];

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private appConfig: AppConfiguration,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(DEFAULT_CURRENCY_CODE) public currency: string
  ) {
    this.defaultImage = this.domSanitizer.bypassSecurityTrustUrl(
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj' +
        '8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3Jh' +
        'cGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9Ij' +
        'AgMCA4MDAwIDQ1MDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4' +
        'bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Im' +
        'h0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9r' +
        'ZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxLjU7Ij48cGF0aCBkPS' +
        'JNMzE0Ljg5NiwyMDguMDc4bDEyLjIxMiw0MDY1bDczNjguNzQsLTAuMzc1bDMzLjM0MywtNDA1Ni4yNGwtNzQxNC4zLC04' +
        'LjM4MVoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNlMmUyZTI7c3Ryb2tlLXdpZHRoOjkyLjc1cHg7Ii8+PGcgaWQ9Ik' +
        'ViZW5lMSI+PHBhdGggZD0iTTIwMjUuNjQsMzEyOC41MWwxMzkxLjc0LC0xMjg1LjlsNzUxLjgzOCwxMjQwLjcxbC0zNDYu' +
        'NDI2LC01NzIuOTMzbDk5MS44MzQsLTc4My42OTdsMTE1Ni41MSwxMzUwLjEyIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZT' +
        'ojZTJlMmUyO3N0cm9rZS13aWR0aDo5Mi43NXB4OyIvPjwvZz48ZyBpZD0iRWJlbmUyIj48cGF0aCBkPSJNMzkzOS4yMywx' +
        'MzA3LjMzYy05MS4zMjcsMi40NiAtMTYzLjU0NSw3OC4xNzMgLTE2MS42OSwxNjkuNTE0YzAuMDc5LDMuODk1IDAuMTU4LD' +
        'cuNzgyIDAuMjM3LDExLjY1MWMxLjUxNCw3NC41NDUgNjIuMzQ4LDEzNC4yMDIgMTM2LjkwOSwxMzQuMjU5YzEwLjAzLDAu' +
        'MDA4IDIwLjIxLDAuMDE1IDMwLjM3OSwwLjAyM2M4OS45MjcsMC4wNjkgMTY0LjAxNiwtNzAuNTggMTY4LjIyLC0xNjAuND' +
        'A4YzAsLTAuMDA1IDAsLTAuMDA5IDAuMDAxLC0wLjAxM2MxLjkzMiwtNDEuMjk3IC0xMy40MTQsLTgxLjUzNCAtNDIuMzU2' +
        'LC0xMTEuMDU2Yy0yOC45NDIsLTI5LjUyMiAtNjguODY4LC00NS42NjMgLTExMC4xOTUsLTQ0LjU1Yy03LjE1NCwwLjE5My' +
        'AtMTQuMzM5LDAuMzg3IC0yMS41MDUsMC41OFoiIHN0eWxlPSJmaWxsOm5vbmU7c3Ryb2tlOiNlMmUyZTI7c3Ryb2tlLXdp' +
        'ZHRoOjkyLjc1cHg7Ii8+PC9nPjwvc3ZnPg=='
    );
    this.context = this.appConfig.context;
  }

  ngOnInit() {
    this.values = this.initializeCardValues(this.fundingProject);
  }

  openDialog() {
    this.hasClickedAssignClerk.emit(<AssignableClerkProjectData>{
      fundingGuidelineId: this.fundingProject.fundingGuideline.id,
      fundingProjectId: this.fundingProject.id,
      fundingProject: this.fundingProject,
      assignedClerks: this.fundingProject.assignedClerks
    });
  }

  onCardButtonClick() {
    this.hasClicked.emit(<GuidelineProjectIds>{
      fundingGuidelineId: this.fundingProject.fundingGuideline.id,
      fundingProjectId: this.fundingProject.id
    });
  }

  initializeCardValues(fundingProject: FundingProject): string[] {
    const valueList: string[] = [];

    if (fundingProject) {
      valueList.push(fundingProject.stateFundingProject?.title);
      valueList.push(fundingProject.location?.title);
      if (fundingProject.user?.organisation) {
        this.items.splice(3, 0, 'Vertreten durch'); //Adding ('Vertreten durch') after ('Antragsteller:in').
        valueList.push(fundingProject.user?.organisation);
      }
      valueList.push(fundingProject.user?.fullName);
      let clerkList = '';
      fundingProject.assignedClerks?.forEach((clerk) => {
        if (clerkList.length > 0) clerkList += ' , ';
        clerkList += clerk.user?.fullName;
      });
      if (this.context === ApplicationContext.MANAGE && clerkList === '') {
        clerkList = 'canAssignClerk';
      }
      valueList.push(clerkList);
      valueList.push(
        formatCurrency(
          fundingProject.requestedFundingValue,
          this.locale,
          this.currency
        )
      );
      valueList.push(fundingProject.oamanId);
      if (
        fundingProject.fundingRequestDTO !== null &&
        fundingProject.fundingRequestDTO.submissionDate
      ) {
        valueList.push(
          stringToDatestring(fundingProject.fundingRequestDTO.submissionDate)
        );
      } else {
        valueList.push('');
      }
      valueList.push(fundingProject.fundingGuideline.title);
      let leaderFundingProject = isLeaderFundingProject(fundingProject);
      if (
        (fundingProject.localActionGroup !== null && leaderFundingProject) ||
        !leaderFundingProject
      ) {
        valueList.push(
          fundingProject.authority.title +
            ' \n' +
            getDepartmentNameById(fundingProject.authorityLocationNumber)
        );
      } else {
        valueList.push('');
      }
    }
    return valueList;
  }
  isProjectInaccessible(): boolean {
    return (
      this.context === ApplicationContext.ENROLL &&
      this.fundingProject.stateFundingProject.resourceKey ===
        FundingProjectStates.WITHDRAWN
    );
  }
}
