import { FundingProjectAuthority } from './funding-project';

export enum TrustLevel {
  HIGH = 'HIGH',
  SUBSTANTIAL = 'SUBSTANTIAL',
  LOW = 'LOW'
}
export interface User {
  id: string;
  nameFirst: string;
  nameLast: string;
  fullName: string;
  email: string;
  trustLevel: TrustLevel;
  authority: FundingProjectAuthority;
  roles: string[];
  role: string;
  identityProvider: string;
  loginType: string;
  authorityLocationNumber: string;
  organisation: string;
}

export interface MailSendingConfigDto {
  enabled: boolean;
  accountType?: string;
}
