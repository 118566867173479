<div id="applications-table">
  <mat-table class="table" [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="title">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.title' | translate"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.table.header.title' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="stateFundingProject">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.status' | translate"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.table.header.status' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.stateFundingProject.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="user">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.applicant' | translate"
        *matHeaderCellDef
      >
        {{ 'fundingProject.table.header.applicant' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.user.organisation ? element.user.organisation : element.user.fullName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.location' | translate"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.table.header.location' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{
        element.location.title
      }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="assignedClerks">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.clerks' | translate"
        *matHeaderCellDef
      >
        {{ 'fundingProject.table.header.clerks' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.assignedClerks | assignedClerks }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="requestedFundingValue">
      <mat-header-cell
        [title]="
          'fundingProject.table.header.a11y.requestedFundingValue' | translate
        "
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.table.header.requestedFundingValue' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-align-end request-funding-value">
        {{ element.requestedFundingValue | currency }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="oamanId">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.oamanId' | translate"
        *matHeaderCellDef
        mat-sort-header
      >
        {{ 'fundingProject.table.header.oamanId' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-align-end">
        {{ element.oamanId }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="submissionDate">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.submissionDate' | translate"
        *matHeaderCellDef
      >
        {{ 'fundingProject.table.header.submissionDate' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-align-end">
        {{ stringToDatestring(element.fundingRequestDTO?.submissionDate) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="guideline">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.guideline' | translate"
        *matHeaderCellDef
      >
        {{ 'fundingProject.table.header.guideline' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-align-end">
        {{ element.fundingGuideline.title }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="authorityInfo">
      <mat-header-cell
        [title]="'fundingProject.table.header.a11y.authorityInfo' | translate"
        *matHeaderCellDef
      >
        {{ 'fundingProject.table.header.authorityInfo' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-align-end">
        {{
          getAuthorityInfo(element)
        }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      class="funding-project-row"
      [ngStyle]="{ cursor: isProjectInaccessible(row) && 'default' }"
      (click)="onRowClick(row)"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
  <mat-paginator
    id="paginator"
    [length]="totalElements"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
