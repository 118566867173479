/**
 * These are characters that are forbidden from the FundingProject, ApplicationDocument
 * creation and fileUpload title fields
 */
export const specialChars: string = '\\/:;*?"<>|&~#%{}+$„“§\'=^@`´'; // special chars without ] and [
export const forbiddenSpecialChars: string = `${specialChars}\[\]`; // special chars with ] and [
export const regExForSpecialCharsExclusion: string = `^[^\\${specialChars}\\\]\\\[]*$`; // regular expression rejecting forbiddenSpecialChars
export const regExForFileName: RegExp = new RegExp(
  '^[^„“´ -/:-@/[-`{-~¡-£¥§ª-¬®-³µ¹-»¿-ÿŒ-œŠ-šŸŽ-ž€]+\\.\\w+$'
); //Elster >=3.6.0 needs more specific Char Exclusion
export const forbiddenCharsInFileName: string =
  '\\/:;*?"<>|&~#%{}+$„“§\'=^@`´[]–€';
