import { AfterViewChecked, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProfileInformation } from 'oaman-components';
import { AppState } from '../../../../../../core/state';
import { selectUserState } from '../../../../../../core/authentication/state/auth.selectors';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements AfterViewChecked {
  dataSource$: Observable<ProfileInformation[]> = of([]);
  displayedColumns: string[] = ['key', 'value'];
  userState$!: Observable<any | null>;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngAfterViewChecked(): void {
    this.store.select(selectUserState).subscribe((info) => {
      let dataSource: ProfileInformation[] = [];
      if (info != undefined) {
        this.translate
          .get(['profile.name', 'profile.email', 'profile.organisation'])
          .subscribe((translations) => {
            dataSource.push({
              key: translations['profile.name'],
              value: info.fullName
            });

            if (info.organisation) {
              dataSource.push({
                key: translations['profile.organisation'],
                value: info.organisation
              });
            }

            dataSource.push({
              key: translations['profile.email'],
              value: info.email
            });
          });
      }
      this.dataSource$ = of(dataSource);
    });
  }
}
